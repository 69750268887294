/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,700i&display=swap');
@import url('./app/styles/ds-ng-select-theme.css'); // temp theme to edit and change ng-select styling

:root {
  --primary: #0063f7;
  --primary-contrast: #fff;
  --nav-primary: #12263f;
  --nav-size: 14px;
  --nav-bg: white;
  --nav-bg-contrast: #12263f;
  --text-font: 'content_font', 'Roboto', sans-serif;
  --heading-font: 'heading_font', 'Roboto', sans-serif;
  --Neutral: #39485f1a;
}

$content-area-bgcolor: #f5f5f5;
$content-color: #12263f;

html,
body {
  font-family: var(--text-font) !important;
  color: #666666 !important;
  //font-family: "Roboto", sans-serif
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--heading-font) !important;
  color: #12263f;
}

.background-gray {
  background-color: #808080;
}

.list-group-item {
  padding: 0.5rem;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.font-weight-500 {
  font-weight: 500;
}

// for easy fixes
.f-8px {
  font-size: 8px;
}

.f-10px {
  font-size: 10px;
}

.f-12px {
  font-size: 12px;
}

.f-14px {
  font-size: 14px;
}

.f-16px {
  font-size: 16px;
}

.f-18px {
  font-size: 18px;
}

.f-26px {
  font-size: 26px;
}

.width-fit-content {
  width: fit-content;
}

.height-fit-content {
  height: fit-content;
}

.min-width-fit-content {
  min-width: fit-content;
}

.primary {
  color: var(--primary);
}

.h-46px {
  height: 46px !important;
}

.w-25p {
  width: 25%;
}

.w-75p {
  width: 75%;
}

.w-33p {
  width: 33.333333%;
  flex: 1 1 33.333333%;
}

.w-66p {
  width: 66.666666%;
  flex: 1 1 66.666666%;
}

.flex-1-1-auto {
  flex: 1 1 auto;
}

.min-width-0 {
  min-width: 0;
}

.text-ellipsis {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

// simple css-only text-tooltip
.ctooltip {
  position: relative;
}

.ctooltip::after {
  background-color: #d8dee7;
  color: black;
  border-radius: 10px;
  padding: 4px 10px;
  position: absolute;
  text-align: center;
  width: fit-content;
  z-index: 999;
  font-size: 12px;
  display: none;
  max-width: 250px;
}

.ctooltip::before {
  background-color: #d8dee7;
  content: ' ';
  display: none;
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 999;
}

.ctooltip:hover::after {
  display: block;
}

.ctooltip:hover::before {
  display: block;
}

.ctooltip.ctooltip-top::after {
  content: attr(data-tooltip);
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 10px));
}

.ctooltip.ctooltip-top::before {
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
}

.ctooltip.ctooltip-bottom::after {
  content: attr(data-tooltip);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 10px));
}

.ctooltip.ctooltip-bottom::before {
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(100% + 5px)) rotate(45deg);
}

.ctooltip.ctooltip-right::after {
  content: attr(data-tooltip);
  top: 0;
  right: 0;
  transform: translateX(calc(100% + 10px));
}

.ctooltip.ctooltip-right::before {
  top: 50%;
  right: 0;
  transform: translate(calc(100% + 5px), -50%) rotate(45deg);
}

.ctooltip.ctooltip-left::after {
  content: attr(data-tooltip);
  top: 0;
  left: 0;
  transform: translateX(calc(-100% - 10px));
}

.ctooltip.ctooltip-left::before {
  top: 50%;
  left: 0;
  transform: translate(calc(-100% - 5px), -50%) rotate(45deg);
}

.cursor-pointer {
  cursor: pointer;
}

// remove close button from marker infowindow
#delivery-order-map button.gm-ui-hover-effect {
  display: none !important;
}

.pt-14px {
  padding-top: 14px;
}

.pb-14px {
  padding-bottom: 14px;
}

.pb-10px {
  padding-bottom: 10px !important;
}

.pb-20px {
  padding-bottom: 20px !important;
}

.py-14px {
  padding-bottom: 14px;
  padding-top: 14px;
}

.px-14px {
  padding-right: 14px;
  padding-left: 14px;
}

.mt-14px {
  margin-top: 14px;
}

.mb-14px {
  margin-bottom: 14px;
}

.my-14px {
  margin-bottom: 14px;
  margin-top: 14px;
}

.mx-14px {
  margin-right: 14px;
  margin-left: 14px;
}

.mx-7px {
  margin-right: 7px;
  margin-left: 7px;
}

@media (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}

// order id title of order details used by all order type component
.orderIdTitle {
  font-size: 1.2rem;
  font-weight: normal;
  color: #000;
}

.pointer-events-none {
  pointer-events: none;
}

// DL-6649
// reduce the width of container to 920px (+ 30px padding given by bootstrap)
// reduce padding of columns to 7px
.max-950px {
  max-width: 950px;
}

.padding-right-7px {
  padding-right: 7px;
}

.padding-left-7px {
  padding-left: 7px;
}

@media (min-width: 768px) {
  .padding-right-md-7px {
    padding-right: 7px;
  }

  .padding-left-md-7px {
    padding-left: 7px;
  }
}

.center-absolutely {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overflow-y-auto {
  overflow-y: auto;
}

.border-1px {
  border: 1px solid #ccc;
}

.border-bottom-1px {
  border-bottom: 1px solid #ccc;
}

.border-left-1px {
  border-left: 1px solid #ccc;
}

.border-right-1px {
  border-right: 1px solid #ccc;
}

.border-top-1px {
  border-top: 1px solid #ccc;
}

@media (min-width: 768px) {
  .border-top-md-0 {
    border-top: none;
  }

  .border-right-md-1px {
    border-right: 1px solid #ccc;
  }
}

.primary-btn {
  display: block;
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--primary-contrast);
  width: 75%;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 2px;
  height: 46px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    color: var(--primary-contrast) !important;
    box-shadow: 0 0 0 0.2rem #ccc;
  }
}

.flex-50p {
  flex: 1 1 50%;
}

@media (min-width: 768px) {
  .flex-md-50p {
    flex: 1 1 50%;
  }
}

.border-radius-5px {
  border-radius: 5px;
}

.splide {
  .splide__arrow--prev {
    left: -1rem;
  }

  .splide__arrow--next {
    right: -1rem;
  }

  .splide__pagination {
    bottom: -2.5rem;
  }

  .splide__pagination__page {
    background-color: gray;
  }

  .splide__pagination__page.is-active {
    background-color: black;
  }
}

.btn {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn-ds-primary {
  //background-color: var(--primary);
  //border-color: var(--primary);
  border-radius: 24px;
  border: 2px solid var(--Neutral, rgba(57, 72, 95, 0.1));
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  //color: var(--primary-contrast);
  color: #05090d;

  &:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary-contrast);
  }

  &:active {
    box-shadow: none;
  }

  &:disabled {
    background-color: #c9ced5;
    border-color: #c9ced5;
    color: #05090d;
  }

  &.solid {
    background-color: var(--primary);
    border-color: var(--primary);
    color: var(--primary-contrast);
  }

  &.grey-shade {
    background-color: var(--Neutral, rgba(57, 72, 95, 0.1));
    border: 0px;

    &:hover {
      color: #05090d;
      background-color: #e5e5ec;
    }
  }
}

.btn-ds-secondary {
  background-color: #6e84a3;
  border-color: #6e84a3;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  color: #fff;

  &:hover {
    background-color: #6e84a3;
    border-color: #6e84a3;
    color: #fff;
  }

  &:active {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    background-color: #b1c2d9;
    border-color: #b1c2d9;
  }
}

.main-content-area {
  min-height: calc(100vh - 240px);
}

.item-list-box {
  background-color: #fff;
}

.content-common-color {
  color: $content-color !important;
}

.overlay-bg {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-image: url(/assets/images/service-unavailable-bg.svg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 70% 90%;
  background-position: 50%;
  z-index: -1;
  background-color: #f8f9fa;
  opacity: 0.9;
}

@media (max-width: 767px) {
  .overlay-bg {
    background-image: url('/assets/images/service-unavailable-mobile.svg');
    background-size: 90% 90%;
  }
}

.max-500px {
  max-width: 500px;
}

.max-764px {
  max-width: 764px;
}

.max-332px {
  max-width: 332px;
}

.max-203px {
  max-width: 203px;
}

.pac-container {
  z-index: 1033;
}

.max-137px {
  max-width: 137px;
}

.otp-failed {
  border-color: red !important;
  border-width: 1.5px !important;
}

.otp-input {
  background-color: #f9fbfd;
  border-color: #d2ddec;
  width: 48px !important;
  height: 48px !important;
}

.otp-input:not(:placeholder-shown) {
  background-color: #fff;
  border-color: #95aac9;
}

@media (min-width: 425px) and (max-width: 768px) {
  .otp-submit {
    margin-top: 7em;
  }
}

@media (max-width: 425px) {
  .otp-submit {
    margin-top: 5em;
  }

  .otp-input {
    width: 2.5em !important;
    height: 2.5em !important;
  }
}

@media (max-width: 264px) {
  .otp-submit {
    margin-top: 10px;
  }

  .otp-input {
    width: 2em !important;
    height: 2em !important;
  }
}

.custom-phone-input-component {
  .iti {
    display: block;
    font-size: 14px;
  }

  .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: #ced4da;
  }

  .dropdown-menu {
    font-size: 14px !important; //do not override or remove this !important as it is used to override ngx-int-tel-input values.
  }

  input[type='tel'] {
    height: 42px;
    font-size: 14px;
  }
}

.nearest-store-summary {
  padding: 20px 0px 20px 0px !important;
  margin: 0px !important;
  background-color: transparent;
  border-bottom: 1px solid #d2ddec;
}

.position-center {
  left: 50% !important;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.cancel-modal-width {
  width: 18.75rem !important;
  margin-left: auto;
  margin-right: auto;
}

.update-phone-number {
  .invalid-phone {
    /* Red border */
    border: 1px solid #FF0000 !important;
    /* Light red background */
    box-shadow: 0 0 0 4px #FFC9C9;
  }
}

.contact-us {
  text-decoration: underline !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  cursor: pointer !important;

  &:hover {
    text-decoration: none !important;
  }
}

.try-again-contact-us-link {
  color: #05090D !important;
  font-size: 14px !important;
  font-weight: 600 !important;

  &:hover {
    text-decoration: none !important;
    color: #0063F7 !important;
    cursor: pointer !important;
  }
}

.contact-us-no-link {
  color: #05090D !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}